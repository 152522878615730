import {IconProps} from '@/ui/icon/types';
import * as React from 'react';
import type {SVGProps} from 'react';
function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" {...props}>
      <path d="M445.92 408a37.682 37.682 0 0 1 21.293 6.559 37.694 37.694 0 0 1 13.863 17.44l148.8 372a133.92 133.92 0 0 0 258.116-49.92V519.6a144.012 144.012 0 0 0 89.695-93.616 144.027 144.027 0 0 0-21.926-127.79A144.002 144.002 0 1 0 791.99 519.6v234.48a37.918 37.918 0 0 1-48.039 36.265A37.911 37.911 0 0 1 718.912 768l-148.8-372a133.92 133.92 0 0 0-258.116 49.92V680.4a144.012 144.012 0 0 0-89.695 93.617 144.027 144.027 0 0 0 21.926 127.79A144.002 144.002 0 1 0 407.998 680.4V445.92a37.934 37.934 0 0 1 11.105-26.816A37.934 37.934 0 0 1 445.919 408zM840 336a48.008 48.008 0 0 1 33.941 14.059C882.941 359.063 888 371.27 888 384s-5.059 24.938-14.059 33.941C864.937 426.941 852.73 432 840 432s-24.938-5.059-33.941-14.059C797.059 408.937 792 396.73 792 384s5.059-24.938 14.059-33.941A48.012 48.012 0 0 1 840 336zM360 864a48.008 48.008 0 0 1-33.941-14.059C317.059 840.937 312 828.73 312 816s5.059-24.938 14.059-33.941C335.063 773.059 347.27 768 360 768s24.938 5.059 33.941 14.059C402.941 791.063 408 803.27 408 816s-5.059 24.938-14.059 33.941A48.012 48.012 0 0 1 360 864z" />
    </svg>
  );
}
export function SvgPath(props: IconProps) {
  const {size, className, ...remainingProps} = props;
  return (
    <Icon
      className={`svg-icon ${className || ''}`}
      width={size}
      height={size}
      fill="currentColor"
      data-icon="path"
      {...remainingProps}
    />
  );
}
