/**
 * @generated SignedSource<<9c76332c0d5c36d73b4ce33377137f26>>
 * @relayHash 056369f4203422ec0400a931d948a522
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 056369f4203422ec0400a931d948a522

import { ConcreteRequest, Query } from 'relay-runtime';
export type networkListQuery$variables = Record<PropertyKey, never>;
export type networkListQuery$data = {
  readonly rootNetworks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly avatarUrl: string | null | undefined;
        readonly id: string;
        readonly isPersonalNetwork: boolean;
        readonly name: string;
        readonly slug: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type networkListQuery = {
  response: networkListQuery$data;
  variables: networkListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "roles",
        "value": [
          "OWNER",
          "NETWORK_MEMBER"
        ]
      }
    ],
    "concreteType": "NetworkRootConnection",
    "kind": "LinkedField",
    "name": "rootNetworks",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkRootEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NetworkRoot",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatarUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPersonalNetwork",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "rootNetworks(roles:[\"OWNER\",\"NETWORK_MEMBER\"])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "networkListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "networkListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "056369f4203422ec0400a931d948a522",
    "metadata": {},
    "name": "networkListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "46c7abf5042a39fd6b2dca11fbe77e65";

export default node;
