import {Flex} from '@mantine/core';

import {Avatar} from '@/ui/avatar/avatar';

import styles from '@/ui/avatar/connection-group/avatar-connection-group.module.css';

type AvatarConnection = {
  name: string | null | undefined;
  avatarUrl: string | null | undefined;
};

type AvatarConnectionGroupProps = {
  left: AvatarConnection | null | undefined;
  center: AvatarConnection | null | undefined;
  right: AvatarConnection | null | undefined;
};

export function AvatarConnectionGroup({left, right, center}: AvatarConnectionGroupProps) {
  return (
    <Flex pos="relative">
      <Avatar name={left?.name || ''} src={left?.avatarUrl} className={styles.left} />
      <Avatar name={right?.name || ''} src={right?.avatarUrl} className={styles.right} />
      <Avatar
        name={center?.name || ''}
        src={center?.avatarUrl}
        size="lg"
        className={styles.center}
      />
    </Flex>
  );
}
