import {useTranslations} from 'next-intl';
import {Center, Stack, Title} from '@mantine/core';
import {SvgPath} from '@/ui/icon/path';
import styles from '@/features/migration-overlay/migration-overlay.module.css';

type Props = {
  name: string;
};

export function MigrationOverlay({name}: Props) {
  const t = useTranslations('features.migrationOverlay');

  return (
    <Center>
      <Stack mt="xl">
        <Title order={2} ta="center" mt="xl">
          {t('title', {name})}
        </Title>
        <Center>
          <Title order={4} ta="center" mb="lg" maw="100vw" w="600px">
            {t('description')}
          </Title>
        </Center>
        <Center>
          <SvgPath className={styles.iconPulse} />
        </Center>
      </Stack>
    </Center>
  );
}
