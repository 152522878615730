import type {PayloadError} from 'relay-runtime';

import {notifications} from '@mantine/notifications';
import {error as logError} from '@/logger/client-logger';

// We can't tie this to the graph because useFragment cannot be used inside of callback
// However, this type represents what we expect back from the ErrorMessage interface,
// which is highly unlikely to be changed in a way that would break this
export type ErrorMessage = {
  __typename: string;
  message: string;
};

type ProcessErrorMessageProps = {
  error: ErrorMessage;
  showNotification?: boolean;
};

export type ProcessErrorProps = PayloadError[] | null;

export function processError(err: Error, message: string) {
  logError(err);

  notifications.show({
    message,
    color: 'red',
  });
}

export function processUnknownErrors(errors: ProcessErrorProps, fallbackMessage: string) {
  // do nothing if there are no errors
  if (errors?.length) {
    for (const error of errors) {
      logError(new Error('Unknown GraphQL error received when processing mutation'), undefined, {
        error,
      });
    }

    const message = errors.find((err) => err.message)?.message || fallbackMessage;
    notifications.show({
      message,
      color: 'red',
    });
  }
}

export function processErrorMessage({error, showNotification = true}: ProcessErrorMessageProps) {
  // We always expect a GenericErrorMessage type here. If we get something else,
  // we want to log an error to alert us that there is a custom error type
  // that isn't being handled.
  if (error.__typename !== 'GenericErrorMessage') {
    logError(
      new Error('Encountered an error message that is not of type GenericErrorMessage'),
      undefined,
      {
        __typename: error.__typename,
        message: error.message,
      },
    );
  }

  // We always want to show a notification for errors that are not handled
  // but it is the default experience in most cases
  if (showNotification) {
    notifications.show({
      message: error.message,
      color: 'red',
    });
  }
}
