/**
 * @generated SignedSource<<12a5e80952dde9746f1b8a12912abc64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUserProvider_currentUser$data = {
  readonly me: {
    readonly __typename: "CurrentUser";
    readonly autojoinEmailDomain: string | null | undefined;
    readonly avatarUrl: string | null | undefined;
    readonly defaultNetwork: {
      readonly id: string;
      readonly slug: string;
    } | null | undefined;
    readonly firstName: string | null | undefined;
    readonly fullName: string | null | undefined;
    readonly id: string;
    readonly impersonatedBy: {
      readonly id: string;
      readonly primaryEmail: string | null | undefined;
    } | null | undefined;
    readonly lastName: string | null | undefined;
    readonly linkedInUrl: string | null | undefined;
    readonly primaryEmail: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "currentUserProvider_currentUser";
};
export type currentUserProvider_currentUser$key = {
  readonly " $data"?: currentUserProvider_currentUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentUserProvider_currentUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryEmail",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./currentUserRefetchQuery.graphql')
    }
  },
  "name": "currentUserProvider_currentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkedInUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autojoinEmailDomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NetworkRoot",
          "kind": "LinkedField",
          "name": "defaultNetwork",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "impersonatedBy",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f0591a0a2e761ef093eb0da8ceb39faa";

export default node;
