import React, {useEffect} from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import {useTranslations} from 'next-intl';
import {Anchor, Container, Divider, Grid, Group, Paper, Stack, Text, Title} from '@mantine/core';
import {Avatar} from '@/ui/avatar/avatar';
import {SvgChevronSmallRight} from '@/ui/icon/chevron-small-right';
import styles from '@/features/network-list/network-list.module.css';
import {useCurrentUser} from '@/providers/current-user-provider';
import {usePathname} from 'next/navigation';
import {useSignOut} from '@/hooks/use-sign-out/use-sign-out';
import {warn} from '@/logger/client-logger';
import {networkListQuery as NetworkListQueryType} from '@/generated/networkListQuery.graphql';
import {PageError} from '@/components/error/page-error';

type Props = {
  slug: string;
};

export function NetworkList({slug}: Props) {
  const {currentUser} = useCurrentUser();
  const pathname = usePathname();
  const {signOut} = useSignOut();
  const t = useTranslations('features.networkList');
  const data = useLazyLoadQuery<NetworkListQueryType>(
    graphql`
      query networkListQuery {
        rootNetworks(roles: [OWNER, NETWORK_MEMBER]) {
          edges {
            node {
              id
              slug
              name
              avatarUrl
              isPersonalNetwork
            }
          }
        }
      }
    `,
    {},
  );

  useEffect(() => {
    const logMessage = 'NetworkList page viewed due to invalid or non-existent slug: ';
    const logObject = {
      slug: slug,
      fullPath: pathname,
      user: {
        name: currentUser?.fullName || '',
        email: currentUser?.primaryEmail,
      },
    };
    warn(logMessage, logObject);
  }, [currentUser?.fullName, currentUser?.primaryEmail, pathname, slug]);

  return data.rootNetworks ? (
    <Container>
      <Title order={2} ta="center" className={styles.title}>
        {t('notFoundMessage')}
      </Title>
      <Title order={4} ta="center" mb="lg">
        {t('notFoundExplanation')}
      </Title>

      <div className={styles.container}>
        <Paper withBorder shadow="md" p="md" radius="lg" style={{flex: 1}}>
          <Title order={5} ta="center" mb="lg">
            {t('helpHeader')}
          </Title>
          <Stack gap="4px" mb="md">
            <Text size="lg" fw="var(--mantine-font-weight-semi-bold)">
              {t('checkUrlLabel')}
            </Text>
            <Text size="lg">{t('checkUrlDescription')}</Text>
          </Stack>
          <Divider my="xs" />
          <Stack gap="4px" mb="md">
            <Text fw="var(--mantine-font-weight-semi-bold)" size="lg">
              <strong>{t('needAccessLabel')}</strong>
            </Text>
            <Text size="lg">{t('needAccessDescription')}</Text>
          </Stack>
          <Divider my="xs" />
          <Stack gap="4px" mb="md">
            <Text fw="var(--mantine-font-weight-semi-bold)" size="lg">
              <strong>{t('lostLabel')}</strong>
            </Text>
            <Text size="lg">
              No worries. You can
              <Anchor onClick={() => signOut()} fw={500}>
                &nbsp;sign out here.
              </Anchor>
            </Text>
          </Stack>
        </Paper>

        <Paper withBorder shadow="md" p="md" radius="lg" style={{flex: 1}}>
          <Title order={5} ta="center" mb="lg">
            {t('listHeader')}
          </Title>
          <Title order={6} ta="center" mb="xl">
            {t('listSubHeader')}
          </Title>
          <Grid justify="center">
            {data.rootNetworks.edges
              ?.flatMap((e) => (e?.node ? e.node : []))
              .map(
                (network) =>
                  network &&
                  network.slug && (
                    <Grid.Col span={12} key={network.id}>
                      <Anchor
                        href={network.slug ? `/${network.slug}` : '/'}
                        underline="never"
                        style={{color: 'inherit'}}
                      >
                        <Paper
                          component="a"
                          withBorder
                          shadow="md"
                          p="md"
                          radius="md"
                          className={`${styles.networkList} ${styles.paperHoverEffect}`}
                        >
                          <Group gap="md" align="center">
                            <Avatar
                              src={network.avatarUrl}
                              name={network.name || ''}
                              variant="outlined"
                            />
                            <Text
                              fw="var(--mantine-font-weight-semi-bold)"
                              className={styles.networkCard}
                            >
                              {network.isPersonalNetwork ? 'Personal Network' : network.name}
                            </Text>
                          </Group>
                          <SvgChevronSmallRight className={styles.networkCard} size={30} />
                        </Paper>
                      </Anchor>
                    </Grid.Col>
                  ),
              )}
          </Grid>
        </Paper>
      </div>
    </Container>
  ) : (
    <PageError error={new Error('Unable to load root network')} reset={() => {}} />
  );
}
