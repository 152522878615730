/**
 * @generated SignedSource<<e7b33349643156c7f353ff4d6947a388>>
 * @relayHash 27a0ee56acc1c910848ce07a78c4650e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 27a0ee56acc1c910848ce07a78c4650e

import { ConcreteRequest, Query } from 'relay-runtime';
export type RootNetworkKind = "PERSONAL" | "TEAM" | "%future added value";
export type useRootNetworkQuery$variables = {
  slug: string;
};
export type useRootNetworkQuery$data = {
  readonly rootNetwork: {
    readonly avatarUrl: string | null | undefined;
    readonly id: string;
    readonly isMigrating: boolean;
    readonly isPersonalNetwork: boolean;
    readonly isTeamNetwork: boolean;
    readonly kind: RootNetworkKind;
    readonly name: string;
    readonly slug: string;
    readonly useMultiverse: boolean;
    readonly websiteUrl: string | null | undefined;
  } | null | undefined;
};
export type useRootNetworkQuery = {
  response: useRootNetworkQuery$data;
  variables: useRootNetworkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "NetworkRoot",
    "kind": "LinkedField",
    "name": "rootNetwork",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatarUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "websiteUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "useMultiverse",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMigrating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTeamNetwork",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPersonalNetwork",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRootNetworkQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRootNetworkQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "27a0ee56acc1c910848ce07a78c4650e",
    "metadata": {},
    "name": "useRootNetworkQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "db7d91bed0de60272387afb86c2a0973";

export default node;
