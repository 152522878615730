import {useTranslations} from 'next-intl';
import styles from '@/components/expert-badge/expert-badge.module.css';

/**
 * Used with Avatar Component to indicate that the user is an expert
 */
export function ExpertBadge(props: React.HTMLProps<HTMLDivElement>) {
  const t = useTranslations('expert');

  return (
    <div className={styles.expertBadge} {...props}>
      {t('base')}
    </div>
  );
}
