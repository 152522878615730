// generated file from scripts/generate-tracking-hook.js
import {usePostHog} from 'posthog-js/react';
import type {CaptureOptions} from 'posthog-js';
import {datadogRum} from '@datadog/browser-rum';
import {useRootNetworkMaybe} from '@/hooks/use-root-network/use-root-network';
import {CLIENT_NAME, CLIENT_VERSION} from '@/config';
import {trackPropertyResolverFunctionMap} from '@/utils/track-property-resolver-function-map';
import type {
  Payload,
  EventName,
  SignUpFormClicked,
  SignUpFormErrored,
  SignUpWithEmailClicked,
  OnboardingInviteTeamSkipStepClicked,
  OnboardingInviteTeamNextClicked,
  SignUpGoogleClicked,
  SignInFormClicked,
  OnboardingSyncNetworkNextClicked,
  OnboardingCreateTeamClicked,
  OnboardingJoinTeamClicked,
  SignInFormErrored,
  SyncNetworkFormTimeoutErrored,
  ConfirmDetailsFormClicked,
  SignInGoogleClicked,
  ResetPasswordFormClicked,
  ResetPasswordFormErrored,
  ForgotPasswordFormErrored,
  ForgotPasswordFormClicked,
  EmailVerificationResendCodeClicked,
  EmailVerificationSubmitCodeClicked,
  NetworkSwitcherCreateTeamNetworkClicked,
  TeamMembersInviteClicked,
  SourcesOpenClicked,
  ChromeExtensionInstallClicked,
  ChromeExtensionBeginSyncClicked,
  ChromeExtensionConfirmProfileClicked,
  SearchStartClicked,
  SearchResultsTriggered,
  SearchClosedClicked,
  SearchOpenAdvancedClicked,
  ProfilePersonClicked,
  ListOpenClicked,
  ListCreateClicked,
  ListAddPersonClicked,
  ListSwitchViewClicked,
  ListEmptyStateViewed,
  ListEmptyStateCtaClicked,
  KanbanPersonDragged,
  ListShareOpenClicked,
  ListShareSendClicked,
  FileUploadProcessStartClicked,
  FileUploadFinishClicked,
  LeftNavSectionClicked,
  DarkModeToggleClicked,
  NetworkSwitcherSelectNetworkClicked,
  LogOutButtonClicked,
  ProfileLinkedinClicked,
  ProfilePhoneClicked,
  ProfileEmailClicked,
  ProfileViewRelationshipsClicked,
  ProfileSectionClicked,
  NotesCreateClicked,
  NotesOpenClicked,
  NotesEditActionClicked,
  ListRenameClicked,
  AutomationCreateClicked,
  AutomationRunClicked,
  AutomationAbandonedClicked,
  AutomationSearchClicked,
  AutomationFiltersSelectedClicked,
  AutomationDeleteClicked,
  AutomationRenameClicked,
  AutomationToggleClicked,
  ChromeExtensionCancelClicked,
  ChromeExtensionCompleteClicked,
  TemplateCreateClicked,
  PublishedListCreatedByAtlasClicked,
  PublishedListViewInAtlasClicked,
  PublishedListLogInClicked,
  PublishedListSignUpClicked,
  PublishedListIntroInstructionsGotItClicked,
  PublishedListIntroInstructionsDoNotShowClicked,
  PublishedListAskForIntrosClicked,
  PublishedListSendRequestClicked,
  PublishedListPersonSelectClicked,
  PublishedListLearnMoreClicked,
  DocumentationLinkClicked,
  NylasEmailAccountConnectedTriggered,
  ComposerCtaClicked,
  ComposerSelectEmailClicked,
  ComposerSelectClientClicked,
  ComposerOpenClicked,
  MobileGateViewDesktopClicked,
  MobileGateRemindMeClicked,
  NavHelpLinkClicked,
  FieldsSelectionVisibilityTriggered,
  PersonalizedFieldsStartClicked,
  PersonalizedFieldsSelectFieldTypeClicked,
  PersonalizedFieldsFinishClicked,
  PersonalizedFieldsCancelClicked,
  PersonalizedFieldsBackClicked,
  PersonalizedFieldsDeleteClicked,
  AllListsOpenClicked,
  AllListsCreateListClicked,
  MagicLinkCopyLinkClicked,
} from '@/types/use-tracking';

const DEFAULT_PROPERTIES = {
  source_name: CLIENT_NAME,
  source_version: CLIENT_VERSION,
};

export function useTrack<T extends keyof Payload>(name: T) {
  const posthog = usePostHog();
  const rootNetwork = useRootNetworkMaybe();

  return (payload: Payload[T]['properties'], phOptions: CaptureOptions = {
    send_instantly: true,
    transport: 'sendBeacon',
  }) => {
    posthog?.capture(name, {
      ...payload,
      ...(trackPropertyResolverFunctionMap[name]
        ? trackPropertyResolverFunctionMap[name]!(payload)
        : {}),
      ...basePropsMap[name],
      ...DEFAULT_PROPERTIES,
      rootNetworkId: rootNetwork?.id,
      rootNetworkSlug: rootNetwork?.slug,
    }, phOptions);
    datadogRum.addAction(name, {
      ...payload,
      ...(trackPropertyResolverFunctionMap[name]
        ? trackPropertyResolverFunctionMap[name]!(payload)
        : {}),
      ...basePropsMap[name],
      ...DEFAULT_PROPERTIES,
      rootNetworkId: rootNetwork?.id,
      rootNetworkSlug: rootNetwork?.slug,
    });
  };
}

const signUpFormClickedBaseProps: Pick<SignUpFormClicked, 'page' | 'target' | 'type'> = {
  page: 'sign_up',
  target: 'form',
  type: 'click',
};

const signUpFormErroredBaseProps: Pick<SignUpFormErrored, 'page' | 'target' | 'type'> = {
  page: 'sign_up',
  target: 'form',
  type: 'error',
};

const signUpWithEmailClickedBaseProps: Pick<SignUpWithEmailClicked, 'page' | 'target' | 'type'> = {
  page: 'sign_up',
  target: 'with_email',
  type: 'click',
};

const onboardingInviteTeamSkipStepClickedBaseProps: Pick<OnboardingInviteTeamSkipStepClicked, 'page' | 'target' | 'type'> = {
  page: 'onboarding',
  target: 'invite_team_skip_step',
  type: 'click',
};

const onboardingInviteTeamNextClickedBaseProps: Pick<OnboardingInviteTeamNextClicked, 'page' | 'target' | 'type'> = {
  page: 'onboarding',
  target: 'invite_team_next',
  type: 'click',
};

const signUpGoogleClickedBaseProps: Pick<SignUpGoogleClicked, 'page' | 'target' | 'type'> = {
  page: 'sign_up',
  target: 'google',
  type: 'click',
};

const signInFormClickedBaseProps: Pick<SignInFormClicked, 'page' | 'target' | 'type'> = {
  page: 'sign_in',
  target: 'form',
  type: 'click',
};

const onboardingSyncNetworkNextClickedBaseProps: Pick<OnboardingSyncNetworkNextClicked, 'page' | 'target' | 'type'> = {
  page: 'onboarding',
  target: 'sync_network_next',
  type: 'click',
};

const onboardingCreateTeamClickedBaseProps: Pick<OnboardingCreateTeamClicked, 'page' | 'target' | 'type'> = {
  page: 'onboarding',
  target: 'create_team',
  type: 'click',
};

const onboardingJoinTeamClickedBaseProps: Pick<OnboardingJoinTeamClicked, 'page' | 'target' | 'type'> = {
  page: 'onboarding',
  target: 'join_team',
  type: 'click',
};

const signInFormErroredBaseProps: Pick<SignInFormErrored, 'page' | 'target' | 'type'> = {
  page: 'sign_in',
  target: 'form',
  type: 'error',
};

const syncNetworkFormTimeoutErroredBaseProps: Pick<SyncNetworkFormTimeoutErrored, 'page' | 'target' | 'type'> = {
  page: 'sync_network',
  target: 'form_timeout',
  type: 'error',
};

const confirmDetailsFormClickedBaseProps: Pick<ConfirmDetailsFormClicked, 'page' | 'target' | 'type'> = {
  page: 'confirm_details',
  target: 'form',
  type: 'click',
};

const signInGoogleClickedBaseProps: Pick<SignInGoogleClicked, 'page' | 'target' | 'type'> = {
  page: 'sign_in',
  target: 'google',
  type: 'click',
};

const resetPasswordFormClickedBaseProps: Pick<ResetPasswordFormClicked, 'page' | 'target' | 'type'> = {
  page: 'reset_password',
  target: 'form',
  type: 'click',
};

const resetPasswordFormErroredBaseProps: Pick<ResetPasswordFormErrored, 'page' | 'target' | 'type'> = {
  page: 'reset_password',
  target: 'form',
  type: 'error',
};

const forgotPasswordFormErroredBaseProps: Pick<ForgotPasswordFormErrored, 'page' | 'target' | 'type'> = {
  page: 'forgot_password',
  target: 'form',
  type: 'error',
};

const forgotPasswordFormClickedBaseProps: Pick<ForgotPasswordFormClicked, 'page' | 'target' | 'type'> = {
  page: 'forgot_password',
  target: 'form',
  type: 'click',
};

const emailVerificationResendCodeClickedBaseProps: Pick<EmailVerificationResendCodeClicked, 'page' | 'target' | 'type'> = {
  page: 'email_verification',
  target: 'resend_code',
  type: 'click',
};

const emailVerificationSubmitCodeClickedBaseProps: Pick<EmailVerificationSubmitCodeClicked, 'page' | 'target' | 'type'> = {
  page: 'email_verification',
  target: 'submit_code',
  type: 'click',
};

const networkSwitcherCreateTeamNetworkClickedBaseProps: Pick<NetworkSwitcherCreateTeamNetworkClicked, 'page' | 'target' | 'type'> = {
  page: 'network_switcher',
  target: 'create_team_network',
  type: 'click',
};

const teamMembersInviteClickedBaseProps: Pick<TeamMembersInviteClicked, 'page' | 'target' | 'type'> = {
  page: 'team_members',
  target: 'invite',
  type: 'click',
};

const sourcesOpenClickedBaseProps: Pick<SourcesOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'sources',
  target: 'open',
  type: 'click',
};

const chromeExtensionInstallClickedBaseProps: Pick<ChromeExtensionInstallClicked, 'page' | 'target' | 'type'> = {
  page: 'chrome_extension',
  target: 'install',
  type: 'click',
};

const chromeExtensionBeginSyncClickedBaseProps: Pick<ChromeExtensionBeginSyncClicked, 'page' | 'target' | 'type'> = {
  page: 'chrome_extension',
  target: 'begin_sync',
  type: 'click',
};

const chromeExtensionConfirmProfileClickedBaseProps: Pick<ChromeExtensionConfirmProfileClicked, 'page' | 'target' | 'type'> = {
  page: 'chrome_extension',
  target: 'confirm_profile',
  type: 'click',
};

const searchStartClickedBaseProps: Pick<SearchStartClicked, 'page' | 'target' | 'type'> = {
  page: 'search',
  target: 'start',
  type: 'click',
};

const searchResultsTriggeredBaseProps: Pick<SearchResultsTriggered, 'page' | 'target' | 'type'> = {
  page: 'search',
  target: 'results',
  type: 'trigger',
};

const searchClosedClickedBaseProps: Pick<SearchClosedClicked, 'page' | 'target' | 'type'> = {
  page: 'search',
  target: 'closed',
  type: 'click',
};

const searchOpenAdvancedClickedBaseProps: Pick<SearchOpenAdvancedClicked, 'page' | 'target' | 'type'> = {
  page: 'search',
  target: 'open_advanced',
  type: 'click',
};

const profilePersonClickedBaseProps: Pick<ProfilePersonClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'person',
  type: 'click',
};

const listOpenClickedBaseProps: Pick<ListOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'open',
  type: 'click',
};

const listCreateClickedBaseProps: Pick<ListCreateClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'create',
  type: 'click',
};

const listAddPersonClickedBaseProps: Pick<ListAddPersonClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'add_person',
  type: 'click',
};

const listSwitchViewClickedBaseProps: Pick<ListSwitchViewClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'switch_view',
  type: 'click',
};

const listEmptyStateViewedBaseProps: Pick<ListEmptyStateViewed, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'empty_state',
  type: 'view',
};

const listEmptyStateCtaClickedBaseProps: Pick<ListEmptyStateCtaClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'empty_state_cta',
  type: 'click',
};

const kanbanPersonDraggedBaseProps: Pick<KanbanPersonDragged, 'page' | 'target' | 'type'> = {
  page: 'kanban',
  target: 'person',
  type: 'drag',
};

const listShareOpenClickedBaseProps: Pick<ListShareOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'share_open',
  type: 'click',
};

const listShareSendClickedBaseProps: Pick<ListShareSendClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'share_send',
  type: 'click',
};

const fileUploadProcessStartClickedBaseProps: Pick<FileUploadProcessStartClicked, 'page' | 'target' | 'type'> = {
  page: 'file_upload',
  target: 'process_start',
  type: 'click',
};

const fileUploadFinishClickedBaseProps: Pick<FileUploadFinishClicked, 'page' | 'target' | 'type'> = {
  page: 'file_upload',
  target: 'finish',
  type: 'click',
};

const leftNavSectionClickedBaseProps: Pick<LeftNavSectionClicked, 'page' | 'target' | 'type'> = {
  page: 'left_nav',
  target: 'section',
  type: 'click',
};

const darkModeToggleClickedBaseProps: Pick<DarkModeToggleClicked, 'page' | 'target' | 'type'> = {
  page: 'dark_mode',
  target: 'toggle',
  type: 'click',
};

const networkSwitcherSelectNetworkClickedBaseProps: Pick<NetworkSwitcherSelectNetworkClicked, 'page' | 'target' | 'type'> = {
  page: 'network_switcher',
  target: 'select_network',
  type: 'click',
};

const logOutButtonClickedBaseProps: Pick<LogOutButtonClicked, 'page' | 'target' | 'type'> = {
  page: 'log_out',
  target: 'button',
  type: 'click',
};

const profileLinkedinClickedBaseProps: Pick<ProfileLinkedinClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'linkedin',
  type: 'click',
};

const profilePhoneClickedBaseProps: Pick<ProfilePhoneClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'phone',
  type: 'click',
};

const profileEmailClickedBaseProps: Pick<ProfileEmailClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'email',
  type: 'click',
};

const profileViewRelationshipsClickedBaseProps: Pick<ProfileViewRelationshipsClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'view_relationships',
  type: 'click',
};

const profileSectionClickedBaseProps: Pick<ProfileSectionClicked, 'page' | 'target' | 'type'> = {
  page: 'profile',
  target: 'section',
  type: 'click',
};

const notesCreateClickedBaseProps: Pick<NotesCreateClicked, 'page' | 'target' | 'type'> = {
  page: 'notes',
  target: 'create',
  type: 'click',
};

const notesOpenClickedBaseProps: Pick<NotesOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'notes',
  target: 'open',
  type: 'click',
};

const notesEditActionClickedBaseProps: Pick<NotesEditActionClicked, 'page' | 'target' | 'type'> = {
  page: 'notes',
  target: 'edit_action',
  type: 'click',
};

const listRenameClickedBaseProps: Pick<ListRenameClicked, 'page' | 'target' | 'type'> = {
  page: 'list',
  target: 'rename',
  type: 'click',
};

const automationCreateClickedBaseProps: Pick<AutomationCreateClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'create',
  type: 'click',
};

const automationRunClickedBaseProps: Pick<AutomationRunClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'run',
  type: 'click',
};

const automationAbandonedClickedBaseProps: Pick<AutomationAbandonedClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'abandoned',
  type: 'click',
};

const automationSearchClickedBaseProps: Pick<AutomationSearchClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'search',
  type: 'click',
};

const automationFiltersSelectedClickedBaseProps: Pick<AutomationFiltersSelectedClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'filters_selected',
  type: 'click',
};

const automationDeleteClickedBaseProps: Pick<AutomationDeleteClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'delete',
  type: 'click',
};

const automationRenameClickedBaseProps: Pick<AutomationRenameClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'rename',
  type: 'click',
};

const automationToggleClickedBaseProps: Pick<AutomationToggleClicked, 'page' | 'target' | 'type'> = {
  page: 'automation',
  target: 'toggle',
  type: 'click',
};

const chromeExtensionCancelClickedBaseProps: Pick<ChromeExtensionCancelClicked, 'page' | 'target' | 'type'> = {
  page: 'chrome_extension',
  target: 'cancel',
  type: 'click',
};

const chromeExtensionCompleteClickedBaseProps: Pick<ChromeExtensionCompleteClicked, 'page' | 'target' | 'type'> = {
  page: 'chrome_extension',
  target: 'complete',
  type: 'click',
};

const templateCreateClickedBaseProps: Pick<TemplateCreateClicked, 'page' | 'target' | 'type'> = {
  page: 'template',
  target: 'create',
  type: 'click',
};

const publishedListCreatedByAtlasClickedBaseProps: Pick<PublishedListCreatedByAtlasClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'created_by_atlas',
  type: 'click',
};

const publishedListViewInAtlasClickedBaseProps: Pick<PublishedListViewInAtlasClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'view_in_atlas',
  type: 'click',
};

const publishedListLogInClickedBaseProps: Pick<PublishedListLogInClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'log_in',
  type: 'click',
};

const publishedListSignUpClickedBaseProps: Pick<PublishedListSignUpClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'sign_up',
  type: 'click',
};

const publishedListIntroInstructionsGotItClickedBaseProps: Pick<PublishedListIntroInstructionsGotItClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'intro_instructions_got_it',
  type: 'click',
};

const publishedListIntroInstructionsDoNotShowClickedBaseProps: Pick<PublishedListIntroInstructionsDoNotShowClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'intro_instructions_do_not_show',
  type: 'click',
};

const publishedListAskForIntrosClickedBaseProps: Pick<PublishedListAskForIntrosClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'ask_for_intros',
  type: 'click',
};

const publishedListSendRequestClickedBaseProps: Pick<PublishedListSendRequestClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'send_request',
  type: 'click',
};

const publishedListPersonSelectClickedBaseProps: Pick<PublishedListPersonSelectClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'person_select',
  type: 'click',
};

const publishedListLearnMoreClickedBaseProps: Pick<PublishedListLearnMoreClicked, 'page' | 'target' | 'type'> = {
  page: 'published_list',
  target: 'learn_more',
  type: 'click',
};

const documentationLinkClickedBaseProps: Pick<DocumentationLinkClicked, 'page' | 'target' | 'type'> = {
  page: 'documentation',
  target: 'link',
  type: 'click',
};

const nylasEmailAccountConnectedTriggeredBaseProps: Pick<NylasEmailAccountConnectedTriggered, 'page' | 'target' | 'type'> = {
  page: 'nylas_email',
  target: 'account_connected',
  type: 'trigger',
};

const composerCtaClickedBaseProps: Pick<ComposerCtaClicked, 'page' | 'target' | 'type'> = {
  page: 'composer',
  target: 'cta',
  type: 'click',
};

const composerSelectEmailClickedBaseProps: Pick<ComposerSelectEmailClicked, 'page' | 'target' | 'type'> = {
  page: 'composer',
  target: 'select_email',
  type: 'click',
};

const composerSelectClientClickedBaseProps: Pick<ComposerSelectClientClicked, 'page' | 'target' | 'type'> = {
  page: 'composer',
  target: 'select_client',
  type: 'click',
};

const composerOpenClickedBaseProps: Pick<ComposerOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'composer',
  target: 'open',
  type: 'click',
};

const mobileGateViewDesktopClickedBaseProps: Pick<MobileGateViewDesktopClicked, 'page' | 'target' | 'type'> = {
  page: 'mobile_gate',
  target: 'view_desktop',
  type: 'click',
};

const mobileGateRemindMeClickedBaseProps: Pick<MobileGateRemindMeClicked, 'page' | 'target' | 'type'> = {
  page: 'mobile_gate',
  target: 'remind_me',
  type: 'click',
};

const navHelpLinkClickedBaseProps: Pick<NavHelpLinkClicked, 'page' | 'target' | 'type'> = {
  page: 'nav',
  target: 'help_link',
  type: 'click',
};

const fieldsSelectionVisibilityTriggeredBaseProps: Pick<FieldsSelectionVisibilityTriggered, 'page' | 'target' | 'type'> = {
  page: 'fields_selection',
  target: 'visibility',
  type: 'trigger',
};

const personalizedFieldsStartClickedBaseProps: Pick<PersonalizedFieldsStartClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'start',
  type: 'click',
};

const personalizedFieldsSelectFieldTypeClickedBaseProps: Pick<PersonalizedFieldsSelectFieldTypeClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'select_field_type',
  type: 'click',
};

const personalizedFieldsFinishClickedBaseProps: Pick<PersonalizedFieldsFinishClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'finish',
  type: 'click',
};

const personalizedFieldsCancelClickedBaseProps: Pick<PersonalizedFieldsCancelClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'cancel',
  type: 'click',
};

const personalizedFieldsBackClickedBaseProps: Pick<PersonalizedFieldsBackClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'back',
  type: 'click',
};

const personalizedFieldsDeleteClickedBaseProps: Pick<PersonalizedFieldsDeleteClicked, 'page' | 'target' | 'type'> = {
  page: 'personalized_fields',
  target: 'delete',
  type: 'click',
};

const allListsOpenClickedBaseProps: Pick<AllListsOpenClicked, 'page' | 'target' | 'type'> = {
  page: 'all_lists',
  target: 'open',
  type: 'click',
};

const allListsCreateListClickedBaseProps: Pick<AllListsCreateListClicked, 'page' | 'target' | 'type'> = {
  page: 'all_lists',
  target: 'create_list',
  type: 'click',
};

const magicLinkCopyLinkClickedBaseProps: Pick<MagicLinkCopyLinkClicked, 'page' | 'target' | 'type'> = {
  page: 'magic_link',
  target: 'copy_link',
  type: 'click',
};

const basePropsMap: Record<keyof Payload, EventName> = {
  all_lists_create_list_clicked: allListsCreateListClickedBaseProps,
  all_lists_open_clicked: allListsOpenClickedBaseProps,
  automation_abandoned_clicked: automationAbandonedClickedBaseProps,
  automation_create_clicked: automationCreateClickedBaseProps,
  automation_delete_clicked: automationDeleteClickedBaseProps,
  automation_filters_selected_clicked: automationFiltersSelectedClickedBaseProps,
  automation_rename_clicked: automationRenameClickedBaseProps,
  automation_run_clicked: automationRunClickedBaseProps,
  automation_search_clicked: automationSearchClickedBaseProps,
  automation_toggle_clicked: automationToggleClickedBaseProps,
  chrome_extension_begin_sync_clicked: chromeExtensionBeginSyncClickedBaseProps,
  chrome_extension_cancel_clicked: chromeExtensionCancelClickedBaseProps,
  chrome_extension_complete_clicked: chromeExtensionCompleteClickedBaseProps,
  chrome_extension_confirm_profile_clicked: chromeExtensionConfirmProfileClickedBaseProps,
  chrome_extension_install_clicked: chromeExtensionInstallClickedBaseProps,
  composer_cta_clicked: composerCtaClickedBaseProps,
  composer_open_clicked: composerOpenClickedBaseProps,
  composer_select_client_clicked: composerSelectClientClickedBaseProps,
  composer_select_email_clicked: composerSelectEmailClickedBaseProps,
  confirm_details_form_clicked: confirmDetailsFormClickedBaseProps,
  dark_mode_toggle_clicked: darkModeToggleClickedBaseProps,
  documentation_link_clicked: documentationLinkClickedBaseProps,
  email_verification_resend_code_clicked: emailVerificationResendCodeClickedBaseProps,
  email_verification_submit_code_clicked: emailVerificationSubmitCodeClickedBaseProps,
  fields_selection_visibility_triggered: fieldsSelectionVisibilityTriggeredBaseProps,
  file_upload_finish_clicked: fileUploadFinishClickedBaseProps,
  file_upload_process_start_clicked: fileUploadProcessStartClickedBaseProps,
  forgot_password_form_clicked: forgotPasswordFormClickedBaseProps,
  forgot_password_form_errored: forgotPasswordFormErroredBaseProps,
  kanban_person_dragged: kanbanPersonDraggedBaseProps,
  left_nav_section_clicked: leftNavSectionClickedBaseProps,
  list_add_person_clicked: listAddPersonClickedBaseProps,
  list_create_clicked: listCreateClickedBaseProps,
  list_empty_state_cta_clicked: listEmptyStateCtaClickedBaseProps,
  list_empty_state_viewed: listEmptyStateViewedBaseProps,
  list_open_clicked: listOpenClickedBaseProps,
  list_rename_clicked: listRenameClickedBaseProps,
  list_share_open_clicked: listShareOpenClickedBaseProps,
  list_share_send_clicked: listShareSendClickedBaseProps,
  list_switch_view_clicked: listSwitchViewClickedBaseProps,
  log_out_button_clicked: logOutButtonClickedBaseProps,
  magic_link_copy_link_clicked: magicLinkCopyLinkClickedBaseProps,
  mobile_gate_remind_me_clicked: mobileGateRemindMeClickedBaseProps,
  mobile_gate_view_desktop_clicked: mobileGateViewDesktopClickedBaseProps,
  nav_help_link_clicked: navHelpLinkClickedBaseProps,
  network_switcher_create_team_network_clicked: networkSwitcherCreateTeamNetworkClickedBaseProps,
  network_switcher_select_network_clicked: networkSwitcherSelectNetworkClickedBaseProps,
  notes_create_clicked: notesCreateClickedBaseProps,
  notes_edit_action_clicked: notesEditActionClickedBaseProps,
  notes_open_clicked: notesOpenClickedBaseProps,
  nylas_email_account_connected_triggered: nylasEmailAccountConnectedTriggeredBaseProps,
  onboarding_create_team_clicked: onboardingCreateTeamClickedBaseProps,
  onboarding_invite_team_next_clicked: onboardingInviteTeamNextClickedBaseProps,
  onboarding_invite_team_skip_step_clicked: onboardingInviteTeamSkipStepClickedBaseProps,
  onboarding_join_team_clicked: onboardingJoinTeamClickedBaseProps,
  onboarding_sync_network_next_clicked: onboardingSyncNetworkNextClickedBaseProps,
  personalized_fields_back_clicked: personalizedFieldsBackClickedBaseProps,
  personalized_fields_cancel_clicked: personalizedFieldsCancelClickedBaseProps,
  personalized_fields_delete_clicked: personalizedFieldsDeleteClickedBaseProps,
  personalized_fields_finish_clicked: personalizedFieldsFinishClickedBaseProps,
  personalized_fields_select_field_type_clicked: personalizedFieldsSelectFieldTypeClickedBaseProps,
  personalized_fields_start_clicked: personalizedFieldsStartClickedBaseProps,
  profile_email_clicked: profileEmailClickedBaseProps,
  profile_linkedin_clicked: profileLinkedinClickedBaseProps,
  profile_person_clicked: profilePersonClickedBaseProps,
  profile_phone_clicked: profilePhoneClickedBaseProps,
  profile_section_clicked: profileSectionClickedBaseProps,
  profile_view_relationships_clicked: profileViewRelationshipsClickedBaseProps,
  published_list_ask_for_intros_clicked: publishedListAskForIntrosClickedBaseProps,
  published_list_created_by_atlas_clicked: publishedListCreatedByAtlasClickedBaseProps,
  published_list_intro_instructions_do_not_show_clicked: publishedListIntroInstructionsDoNotShowClickedBaseProps,
  published_list_intro_instructions_got_it_clicked: publishedListIntroInstructionsGotItClickedBaseProps,
  published_list_learn_more_clicked: publishedListLearnMoreClickedBaseProps,
  published_list_log_in_clicked: publishedListLogInClickedBaseProps,
  published_list_person_select_clicked: publishedListPersonSelectClickedBaseProps,
  published_list_send_request_clicked: publishedListSendRequestClickedBaseProps,
  published_list_sign_up_clicked: publishedListSignUpClickedBaseProps,
  published_list_view_in_atlas_clicked: publishedListViewInAtlasClickedBaseProps,
  reset_password_form_clicked: resetPasswordFormClickedBaseProps,
  reset_password_form_errored: resetPasswordFormErroredBaseProps,
  search_closed_clicked: searchClosedClickedBaseProps,
  search_open_advanced_clicked: searchOpenAdvancedClickedBaseProps,
  search_results_triggered: searchResultsTriggeredBaseProps,
  search_start_clicked: searchStartClickedBaseProps,
  sign_in_form_clicked: signInFormClickedBaseProps,
  sign_in_form_errored: signInFormErroredBaseProps,
  sign_in_google_clicked: signInGoogleClickedBaseProps,
  sign_up_form_clicked: signUpFormClickedBaseProps,
  sign_up_form_errored: signUpFormErroredBaseProps,
  sign_up_google_clicked: signUpGoogleClickedBaseProps,
  sign_up_with_email_clicked: signUpWithEmailClickedBaseProps,
  sources_open_clicked: sourcesOpenClickedBaseProps,
  sync_network_form_timeout_errored: syncNetworkFormTimeoutErroredBaseProps,
  team_members_invite_clicked: teamMembersInviteClickedBaseProps,
  template_create_clicked: templateCreateClickedBaseProps,
};
