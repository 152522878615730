'use client';

import {graphql, useLazyLoadQuery} from 'react-relay';
import {Loader} from '@mantine/core';
import {createContext, useContext, PropsWithChildren, Suspense} from 'react';

import {NetworkList} from '@/features/network-list/network-list';
import {MigrationOverlay} from '@/features/migration-overlay/migration-overlay';

import {
  useRootNetworkQuery as UseRootNetworkQueryType,
  useRootNetworkQuery$data,
} from '@/generated/useRootNetworkQuery.graphql';

import styles from '@/app/[locale]/(with-global-contexts)/[slug]/(withRootNetworkSidebar)/layout.module.css';

export type RootNetworkContextValue = NonNullable<useRootNetworkQuery$data['rootNetwork']>;

// A root network context to provide access to the current root network
// without coupling to the next route/navigation apis
export const RootNetworkContext = createContext<RootNetworkContextValue | null>(null);

export function RootNetworkProvider({children, slug}: PropsWithChildren & {slug: string}) {
  const data = useLazyLoadQuery<UseRootNetworkQueryType>(
    graphql`
      query useRootNetworkQuery($slug: String!) {
        rootNetwork(slug: $slug) {
          slug
          id
          kind
          name
          avatarUrl
          websiteUrl
          useMultiverse
          isMigrating
          isTeamNetwork
          isPersonalNetwork
        }
      }
    `,
    {slug},
  );

  if (!data.rootNetwork) {
    return (
      <Suspense fallback={<Loader className={styles.loader} />}>
        <NetworkList slug={slug} />
      </Suspense>
    );
  }

  if (data.rootNetwork.isMigrating) {
    return <MigrationOverlay name={data.rootNetwork.name} />;
  }

  return (
    <RootNetworkContext.Provider value={data.rootNetwork}>{children}</RootNetworkContext.Provider>
  );
}

export function useRootNetwork(): RootNetworkContextValue {
  const context = useRootNetworkMaybe();

  if (!context) {
    throw new Error('useRootNetwork must be used within a RootNetworkContext');
  }

  return context;
}

export function useRootNetworkMaybe() {
  const context = useContext(RootNetworkContext);

  return context;
}
