import {useState, useEffect, Dispatch, SetStateAction} from 'react';

export enum LocalStorageKey {
  CxListId = 'cxListId',
  DefaultEmailClient = 'defaultEmailClient',
  DismissedCsvListExports = 'dismissedCsvListExports',
  ViewedInviteTeamWelcomeModals = 'viewedTeamWelcomeModals',
  ViewedCreateTeamWelcomeModals = 'viewedCreatedTeamWelcomeModals',
  BackToAtlasPath = 'backToAtlasPath',
  TeamCreated = 'teamCreated',
  DisableRequestIntroPopover = 'disableRequestIntroPopover',
  SidebarWidth = 'sidebarWidth',
  PeekNavPopover = 'peekNavPopover',
}

export enum LocalStorageKeySuffix {
  ColumnOrder = 'columnOrder',
  ColumnWidths = 'columnWidths',
  LastRootNetwork = 'lastRootNetwork',
}
type LocalStorageDynamicKey = `${string}_${LocalStorageKeySuffix}`;

export function generateLocalStorageKey(
  identifier: string | undefined,
  suffix: LocalStorageKeySuffix,
): LocalStorageDynamicKey {
  return `${identifier}_${suffix}`;
}

type sharedLocalStorageArgs = {
  key: LocalStorageKey | LocalStorageDynamicKey;
  jsonEncode: boolean;
  disable?: boolean;
};

type removeLocalStorageStateFn = (key: string) => void;

function setLocalStorage<T>({
  key,
  value,
  jsonEncode,
  disable,
}: sharedLocalStorageArgs & {value: T}) {
  const toStore = jsonEncode ? JSON.stringify(value) : `${value}`; // typecasting
  if (!disable) {
    localStorage.setItem(key, toStore);
  }
}

function getFromLocalStorage<T>({
  key,
  jsonEncode,
  initialValue,
}: sharedLocalStorageArgs & {initialValue: T}) {
  const raw = typeof window != 'undefined' ? localStorage.getItem(key) : initialValue;

  if (!raw) {
    return initialValue;
  }

  if (jsonEncode) {
    try {
      return JSON.parse(`${raw}`); // typecasting for parse
    } catch (e) {
      // could not decode properly, return initial
      return initialValue;
    }
  }
  return raw;
}

export function useLocalStorageState<T>({
  key,
  jsonEncode,
  initialValue,
  disable,
}: sharedLocalStorageArgs & {
  initialValue: T;
}): [T, Dispatch<SetStateAction<T>>, removeLocalStorageStateFn] {
  const [storedState, setStoredState] = useState<T>(
    getFromLocalStorage<T>({key, jsonEncode, initialValue}),
  );

  function removeStoredState(key: string) {
    localStorage.removeItem(key);
  }

  useEffect(() => {
    setLocalStorage<T>({key, value: storedState, jsonEncode, disable});
  }, [storedState, jsonEncode, key, disable]);

  return [storedState, setStoredState, removeStoredState]; // mimic the react api so we can easily descructure and rename
}
