import {IconProps} from '@/ui/icon/types';
import * as React from 'react';
import type {SVGProps} from 'react';
function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1600 1600" {...props}>
      <path
        fill="#000"
        d="M579.837 532.776c-31.773-31.734-31.773-83.234 0-114.964C595.723 401.94 616.527 394 637.339 394c20.812 0 41.616 7.939 57.501 23.821l325.33 325.198c31.77 31.734 31.77 83.234 0 114.963L694.84 1183.18c-31.773 31.76-83.238 31.76-115.01 0-31.773-31.73-31.773-83.23 0-114.96l267.825-267.719-267.818-267.725Z"
      />
    </svg>
  );
}
export function SvgChevronSmallRight(props: IconProps) {
  const {size, className, ...remainingProps} = props;
  return (
    <Icon
      className={`svg-icon ${className || ''}`}
      width={size}
      height={size}
      fill="currentColor"
      data-icon="chevron-small-right"
      {...remainingProps}
    />
  );
}
