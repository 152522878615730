import {graphql, useMutation} from 'react-relay';
import {useRouter} from 'next/navigation';
import posthog from 'posthog-js';
import {useRumClient} from '@/providers/datadog-rum';

import {useTrack} from '@/utils/use-tracking';
import {useClearLocalCaches} from '@/hooks/use-clear-local-caches/use-clear-local-caches';
import {debug} from '@/logger/client-logger';
import type {
  useSignOutMutation as UseSignOutMutationType,
  useSignOutMutation$data,
} from '@/generated/useSignOutMutation.graphql';
import {useNetworkErrorHandler} from '@/hooks/use-network-error-handler/use-network-error-handler';

import {
  processErrorMessage,
  useOnMutationCompleted,
} from '@/hooks/use-mutation-response/use-mutation-response';

// TODO: We should use the FS SDK instead to not have to do all this:
// https://github.com/fullstorydev/fullstory-browser-sdk/tree/main
// https://app.clickup.com/t/8685u1x0u
type Fullstory = {
  anonymize(): void;
};

declare let FS: Fullstory;

export function useSignOut() {
  const router = useRouter();
  const rum = useRumClient();
  const trackLogOutButtonClicked = useTrack('log_out_button_clicked');
  const {clearLocalCaches} = useClearLocalCaches();

  const onError = useNetworkErrorHandler();

  const [commit, isInFlight] = useMutation<UseSignOutMutationType>(graphql`
    mutation useSignOutMutation($input: SignOutInput!) {
      signOut(input: $input) {
        success
        errors {
          __typename
          message
        }
      }
    }
  `);

  const onCompleted = useOnMutationCompleted<useSignOutMutation$data>(({data}) => {
    const errors = data.signOut?.errors;

    if (errors?.length) {
      debug('useSignOutMutation completed with errors', {
        errors,
      });

      for (const error of errors) {
        processErrorMessage({error});
      }
    }

    posthog?.reset();
    rum.clearUser();
    if (typeof FS !== 'undefined') {
      FS.anonymize();
    }
    clearLocalCaches();

    router.push('/sign-in');
  });

  return {
    signOut: () => {
      trackLogOutButtonClicked({});
      commit({
        variables: {input: {}},
        onCompleted,
        onError,
      });
    },
    signOutInFlight: isInFlight,
  };
}
