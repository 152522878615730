import type {PayloadError} from 'relay-runtime';
import {useCallback} from 'react';
import {error as logError} from '@/logger/client-logger';
import {useTranslations} from 'next-intl';
import {notifications} from '@mantine/notifications';

// there are a ton of imports to update here that I don't want to mess with in this PR
// https://linear.app/hunt-club/issue/ENG-222/update-all-processimports
export {processErrorMessage} from '@/utils/process-error-message';

export type ProcessErrorProps = PayloadError[] | null;
export type CallbackParams<T, K> = {data: T; errors: ProcessErrorProps; extra?: K};

export function useOnMutationCompleted<T, K = never>(
  callback: (props: CallbackParams<T, K>) => void,
) {
  const t = useTranslations();

  // This function reads through all existing unknown errors and logs them + shows a notification
  const processUnknownError = useCallback(
    function (errors: ProcessErrorProps) {
      // do nothing if there are no errors
      if (errors?.length) {
        for (const error of errors) {
          logError(
            new Error('Unknown GraphQL error received when processing mutation'),
            undefined,
            {error},
          );
        }

        const message = errors.find((err) => err.message)?.message || t('utils.networkError');
        notifications.show({
          message,
          color: 'red',
        });
      }
    },
    [t],
  );

  // Need to map the relay onCompleted params to ensure everything gets wired up correctly
  return function onCompleted(
    data: T,
    errors: ProcessErrorProps,
    extra?: K,
    skipEarlyReturn?: boolean,
  ) {
    processUnknownError(errors);

    // If unknown errors occur, don't call the callback
    // they will be shown to the user in the processing above
    if (errors && errors?.length > 0 && !skipEarlyReturn) {
      return;
    }

    callback({data, errors, extra});
  };
}
