'use client';

import {useCallback} from 'react';
import {useTranslations} from 'next-intl';
import {error} from '@/logger/client-logger';
import {notifications} from '@mantine/notifications';

export function useNetworkErrorHandler(): (err: Error) => void {
  const t = useTranslations();

  return useCallback(
    function onError(err: Error) {
      error(err);

      notifications.show({
        message: t('utils.networkError'),
        color: 'red',
      });
    },
    [t],
  );
}
