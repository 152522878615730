import {commitLocalUpdate, useRelayEnvironment} from 'react-relay';

import {closeCable} from '@/relay-environment';
import {LocalStorageKeySuffix} from '@/hooks/use-local-storage-state/use-local-storage-state';

export function useClearLocalCaches() {
  const environment = useRelayEnvironment();

  function clearWebsockets() {
    closeCable();
  }

  function clearGraphqlCache() {
    commitLocalUpdate(environment, (store) => {
      store.invalidateStore();
    });
  }

  function clearLocalStorageCache() {
    const keysToRemove: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (
        key &&
        !key.endsWith(LocalStorageKeySuffix.ColumnOrder) &&
        !key.endsWith(LocalStorageKeySuffix.ColumnWidths) &&
        !key.endsWith(LocalStorageKeySuffix.LastRootNetwork)
      ) {
        keysToRemove.push(key);
      }
    }

    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
  }

  function clearSessionStorageCache() {
    sessionStorage.clear();
  }

  return {
    clearWebsockets,
    clearGraphqlCache,
    clearLocalStorageCache,
    clearSessionStorageCache,
    clearLocalCaches() {
      clearGraphqlCache();
      clearLocalStorageCache();
      clearSessionStorageCache();
      clearWebsockets();
    },
  };
}
