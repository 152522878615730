'use client';

import posthog from 'posthog-js';
import {useRouter} from 'next/navigation';
import {graphql, useLazyLoadQuery, useRefetchableFragment} from 'react-relay';
import {ReactNode, createContext, useCallback, useContext, useEffect} from 'react';

import {debug} from '@/logger/client-logger';
import {useRumClient} from '@/providers/datadog-rum';

import type {
  currentUserProvider_currentUser$key,
  currentUserProvider_currentUser$data,
} from '@/generated/currentUserProvider_currentUser.graphql';
import {currentUserProviderQuery as CurrentUserProviderQueryType} from '@/generated/currentUserProviderQuery.graphql';

type onCompleteRefetchFn = () => void;
type refreshFnArgs = {onComplete?: onCompleteRefetchFn};

export const CurrentUserContext = createContext<{
  refreshCurrentUser: (args?: refreshFnArgs) => void;
  currentUser?: currentUserProvider_currentUser$data['me'];
} | null>(null);

interface Props {
  children: ReactNode;
}

// TODO: We should use the FS SDK instead to not have to do all this:
// https://github.com/fullstorydev/fullstory-browser-sdk/tree/main
// https://app.clickup.com/t/8685u1x0u
type Fullstory = {
  identify(uid: string, userVars?: {displayName: string; email: string | null}): void;
};

declare let FS: Fullstory;

function identifyFullStory({
  id,
  fullName,
  primaryEmail,
  impersonatedBy,
}: NonNullable<currentUserProvider_currentUser$data['me']>) {
  if (typeof FS === 'undefined') return;

  const userVars = {
    displayName: fullName || '',
    email: primaryEmail || null,
    impersonatedById: impersonatedBy?.id,
  };

  FS.identify(id, userVars);
}

function identifyPostHog({
  id,
  fullName,
  primaryEmail,
  impersonatedBy,
}: NonNullable<currentUserProvider_currentUser$data['me']>) {
  posthog?.identify(id, {
    email: primaryEmail,
    name: fullName || '',
    impersonatedById: impersonatedBy?.id,
  });
}

function identifyDataDogRum(
  rum: ReturnType<typeof useRumClient>,
  {
    id,
    fullName,
    primaryEmail,
    impersonatedBy,
  }: NonNullable<currentUserProvider_currentUser$data['me']>,
) {
  rum.setUser({
    id,
    email: primaryEmail || undefined,
    name: fullName || '',
    impersonatedById: impersonatedBy?.id,
  });
}

// This provider may suspend because useLazyLoadQuery suspends.
// Ideally all uses are initially fetched on the server so the suspense state is not shown to users
export function CurrentUserProvider({children}: Props) {
  const rum = useRumClient();

  const parentQuery = useLazyLoadQuery<CurrentUserProviderQueryType>(
    graphql`
      query currentUserProviderQuery {
        ...currentUserProvider_currentUser
      }
    `,
    {},
  );

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment currentUserProvider_currentUser on Query
      @refetchable(queryName: "currentUserRefetchQuery") {
        me {
          __typename
          id
          firstName
          fullName
          lastName
          primaryEmail
          linkedInUrl
          avatarUrl
          autojoinEmailDomain
          defaultNetwork {
            id
            slug
          }
          impersonatedBy {
            id
            primaryEmail
          }
        }
      }
    `,
    parentQuery as currentUserProvider_currentUser$key,
  );

  const refreshCurrentUser = useCallback(
    ({onComplete}: {onComplete?: () => void} = {}) => {
      refetch({}, {fetchPolicy: 'network-only', onComplete});
    },
    [refetch],
  );

  useEffect(() => {
    if (data?.me) {
      identifyFullStory(data.me);
      identifyPostHog(data.me);
      identifyDataDogRum(rum, data.me);
    }
  }, [data, rum]);

  return (
    <CurrentUserContext.Provider value={{currentUser: data?.me, refreshCurrentUser}}>
      {children}
    </CurrentUserContext.Provider>
  );
}

type UseCurrentUserProps = {
  redirectTo?: string;
  allowRedirect?: boolean;
};

export function useCurrentUserMaybe() {
  const context = useContext(CurrentUserContext);

  return context;
}

export function useCurrentUser({redirectTo, allowRedirect = true}: UseCurrentUserProps = {}) {
  const router = useRouter();

  const context = useCurrentUserMaybe();

  if (!context) {
    throw new Error('CurrentUserContext must be used within a CurrentUserProvider');
  }

  // if the user is not logged in, redirect to the login page when not on an excluded path
  if (!context?.currentUser) {
    if (allowRedirect) {
      const redirectLocation = redirectTo ?? '/sign-in';
      debug(`currentUserProviderQuery: user is not logged in, redirecting to ${redirectLocation}`);
      router.push(redirectLocation);
    }
  }

  return context;
}
